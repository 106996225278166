import BaseTopNav from '@/components/base/baseTopNav';
import { useTranslation } from 'react-i18next';
import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/react';
import { history } from '@umijs/max';
import { useTheme } from 'next-themes';
import { useState } from 'react';
import { MaterialSymbolsLanguage } from '@/assets/icons/mine/MaterialSymbolsLanguage';
import PageEnum from '@/enums/pageEnum';
import { MaterialSymbolsSettings } from '@/assets/icons/mine/MaterialSymbolsSettings';
import { MiSort } from '@/assets/icons/comm/MiSort';
import { MaterialSymbolsArrowUpwardRounded } from '@/assets/icons/comm/MaterialSymbolsArrowUpwardRounded';
import { MaterialSymbolsArrowDownwardRounded } from '@/assets/icons/comm/MaterialSymbolsArrowDownwardRounded';
import { MaterialSymbolsOtherAdmissionOutline } from '@/assets/icons/mine/MaterialSymbolsOtherAdmissionOutline';
import { GetTextEnum } from '@/enums/businessEnum';
import { MaterialSymbolsHelpOutlineRounded } from '@/assets/icons/comm/MaterialSymbolsHelpOutlineRounded';
import { MaterialSymbolsVerifiedUserRounded } from '@/assets/icons/mine/MaterialSymbolsVerifiedUserRounded';

export default function SettingsView({ isShowNav = true }: IProps) {
  const { t } = useTranslation();
  const { language, languageList } = useModel('language');
  const { theme } = useTheme();
  const { upDownColorConfig } = useModel('system');
  const { currentExchangeSetting } = useModel('exchange');
  const [functionalDivision] = useState<any[]>([
    {
      title: t('通用'),
      list: [
        {
          label: t('语言'),
          icon: MaterialSymbolsLanguage,
          url: PageEnum.USER_LANGUAGE,
          remark: languageList?.find((item: any) => {
            return item.code === language;
          })?.name,
        },
        {
          label: t('币种'),
          icon: MaterialSymbolsLanguage,
          url: PageEnum.EXCHANGE_RATE,
          remark: currentExchangeSetting?.exchange ?? "",
        },
      ],
    },
    {
      title: t('主题'),
      list: [
        {
          label: t('主题'),
          icon: MaterialSymbolsSettings,
          url: PageEnum.THEME,
          remark: theme === 'light' ? t('白天模式') : t('黑夜模式'),
        },
        {
          label: t('涨跌色设置'),
          icon: MiSort,
          url: PageEnum.UPDOWNCOLOR,
          remark: (
            <div className="flex">
              <MaterialSymbolsArrowUpwardRounded
                width="1.2em"
                height="1.2em"
                className={`${
                  upDownColorConfig === 'upGreen'
                    ? 'text-successColor'
                    : 'text-errorColor'
                } -mr-0.5`}
              />
              <MaterialSymbolsArrowDownwardRounded
                width="1.2em"
                height="1.2em"
                className={`${
                  upDownColorConfig !== 'upGreen'
                    ? 'text-successColor'
                    : 'text-errorColor'
                }`}
              />
            </div>
          ),
        },
      ],
    },
    {
      title: t('时区'),
      list: [
        {
          label: t('时区设置'),
          icon: MaterialSymbolsOtherAdmissionOutline,
          url: PageEnum.TIMEZONESELECTOR,
        },
      ],
    },

    {
      title: t('其他'),
      list: [
        {
          label: t('公司简介'),
          icon: MaterialSymbolsOtherAdmissionOutline,
          onClick: () => {
            history.push(PageEnum.TEXT_PREVIEW, {
              title: t('公司简介'),
              description: t('下方内容是公司基本介绍，如有疑问请联系客服'),
              type: GetTextEnum.COMPANY_INTRODUCTION,
            });
          },
        },
        {
          label: t('帮助 & 支持'),
          icon: MaterialSymbolsHelpOutlineRounded,
          url: PageEnum.HELPCENTER,
        },
        {
          label: t('关于我们'),
          icon: MaterialSymbolsVerifiedUserRounded,
          onClick: () => {
            history.push(PageEnum.TEXT_PREVIEW, {
              title: t('关于我们'),
              description: t('下方内容是关于我们的一些信息'),
              type: GetTextEnum.APP_ABOUT_US,
            });
          },
        },
        {
          label: t('冻结声明'),
          icon: MaterialSymbolsVerifiedUserRounded,
          onClick: () => {
            history.push(PageEnum.TEXT_PREVIEW, {
              title: t('冻结声明'),
              description: t('下方内容是冻结声明，如有疑问请联系客服'),
              type: GetTextEnum.FREEZE_NOTICES,
            });
          },
        },
      ],
    },
  ]);
  const { isMobile } = useModel('system');

  return (
    <div className="">
      {isMobile && isShowNav && <BaseTopNav />}
      <div className="px-4 mt-4 sm:mt-10">
        <div className="text-xl text-backContrastColor">{t('设置')}</div>
      </div>
      <div className="px-4 mt-6">
        {functionalDivision?.map((functional, index) => {
          return (
            <div
              key={index}
              className=" sm:border-1 sm:border-backgroundAuxiliaryColor sm:rounded-md sm:px-2 sm:py-2 sm:mb-6 sm:pt-4"
            >
              <div className="mb-3 text-auxiliaryTextColor">
                {functional.title}
              </div>
              {functional?.list?.map((item: any) => {
                return (
                  <div
                    key={item.label}
                    className="flex cursor-pointer justify-between py-[10px] "
                    onClick={() => {
                      if (item?.url) {
                        history.push(item.url);
                      }
                      if (item?.onClick) {
                        item?.onClick();
                      }
                    }}
                  >
                    <div className="text-base">{item.label}</div>
                    <div className="flex justify-between text-auxiliaryTextColor items-center ">
                      <span>{(item as any)?.remark}</span>
                      <PhCaretRight
                        className="font-bold ml-1"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                  </div>
                );
              })}
              {index !== functionalDivision.length - 1 && (
                <Divider className="mb-4 !bg-backgroundAuxiliaryColor sm:hidden" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface IProps {
  isShowNav?: boolean;
}
